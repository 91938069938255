import React from "react";
import {StaticImage} from "gatsby-plugin-image"

import LayoutWidget from "../../../components/widgets/layout";
import Seo from "../../../components/seo";
import RentwareArticleBooking from "../../../components/rentwareArticleBooking";

const DemoTeslaPage = () => {

    return (
        <LayoutWidget>
            <Seo
                seo={{
                    metaTitle: "Demo",
                    metaDescription: "It looks like you got to the demo page",
                }}
            />

            <div className="hidden mb-16 text-center lg:block">
                <StaticImage
                    src="../../../images/tesla-red@1440.jpg"
                    alt="Tesla red"
                    layout="constrained"
                    width={1440}
                    quality="80"
                />
            </div>
            <div
                className="p-4 mb-12 lg:flex lg:max-w-[1440px] mx-auto lg:space-x-12 items-center lg:px-8 xl:px-10">
                <div className="max-w-xs mx-auto lg:max-w-md">
                    <StaticImage
                        src="../../../images/tesla-dark.jpg"
                        alt="Tesla dark"
                        layout="constrained"
                        width={400}
                        className=""
                    />
                </div>
                <div className="max-w-xs mx-auto mb-6 md:max-w-prose">
                    <h1 className="mb-2 text-lg md:text-2xl">Tesla Model S</h1>
                    <h2 className="mb-4 text-sm md:text-lg">402mi Range (EPA est.)</h2>
                    <p className="text-xs md:text-sm leading-[1.4em]">The Tesla Model S was the 2013 World Green Car
                        of the Year, 2013 Motor Trend Car of the Year, Automobile magazine's 2013 Car of the Year,
                        Time Magazine's Best 25 Inventions of the Year 2012, and Consumer Reports' top-scoring car
                        in road testing.</p>
                </div>

                <RentwareArticleBooking articleId="GXHBU7" />
            </div>

            <div className="item-specs-devider relative hidden md:block px-4 lg:px-8 mx-auto max-w-[1440px] mb-10">
                <div className="border-b border-[#EDEDEF]"/>
            </div>

            <div className="p-4 lg:px-8">
                <div
                    className="grid max-w-xs grid-cols-1 mx-auto md:max-w-prose gap-y-3 md:grid-cols-2 gap-x-3 lg:max-w-[1440px] lg:grid-cols-4">
                    <div>
                        <h3 className="mb-4">Dimensions</h3>
                        <dl className="grid grid-cols-2 mb-5 text-xs item gap-x-1 gap-y-2">
                            <dt>Passengers</dt>
                            <dd>5</dd>
                            <dt>Weight</dt>
                            <dd>1200kg</dd>
                            <dt>Lenght</dt>
                            <dd>4,56m</dd>
                            <dt>Width</dt>
                            <dd>1,61m</dd>
                            <dt>Height</dt>
                            <dd>1,55m</dd>
                        </dl>
                    </div>
                    <div>
                        <h3 className="mb-4">Configuration</h3>
                        <dl className="grid grid-cols-2 mb-5 text-xs item gap-x-1 gap-y-2">
                            <dt>Doors</dt>
                            <dd>4</dd>
                            <dt>Charging</dt>
                            <dd>110V</dd>
                            <dt>Acceleration</dt>
                            <dd>3.1s</dd>
                            <dt>Range</dt>
                            <dd>663km</dd>
                            <dt>Top Speed</dt>
                            <dd>250km/h</dd>
                            <dt>Drivetrain</dt>
                            <dd>AWD</dd>
                        </dl>
                    </div>
                    <div className="md:col-span-2">
                        <h3 className="mb-4">Features</h3>
                        <dl className="grid grid-cols-2 mb-5 text-xs col item gap-x-1 gap-y-2 md:grid-cols-4">
                            <dt>A/C</dt>
                            <dd>Equipped</dd>
                            <dt>Winter tires</dt>
                            <dd>Optional</dd>
                            <dt>Navigation</dt>
                            <dd>Optional</dd>
                            <dt>Radio</dt>
                            <dd>Equipped</dd>
                            <dt>Power steering</dt>
                            <dd>Equipped</dd>
                            <dt>Storage</dt>
                            <dd>Medium</dd>
                            <dt>Roof tent</dt>
                            <dd>Optional</dd>
                            <dt>First aid kit</dt>
                            <dd>Equipped</dd>
                        </dl>
                    </div>
                </div>
            </div>
                
        </LayoutWidget>
    )
};

export default DemoTeslaPage;
